import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import cn from 'classnames'

import Typography from '../Common/Typography'
import { Button } from '../Common/Button'
import Container from '../Common/Container'
import ContactForm, { IContactModalState } from '../contact-form'
import TryForFreeForm from '../contact-form/TryForFreeForm'

import TelegramLogo from '../../svg/telegram-logo'
import { toLink } from '../../utils'
import { telegramLink } from '../../data'
import { useTranslations } from '../../hooks/use-translations'
import { GlobalContext } from '../../context/GlobalContext'
import { AppRegionEnum } from '../../types/app-region.enum'

import * as s from './AboutSection.module.scss'

const AboutSection: React.FC = () => {
  const images = useStaticQuery(imagesQuery)
  const { t } = useTranslations()
  const { region } = React.useContext(GlobalContext)

  const [modal, setModal] = React.useState<IContactModalState>()
  const [sandboxModal, setSandboxModal] = React.useState(false)

  return (
    <>
      {sandboxModal && (
        <TryForFreeForm
          submitBtnText={t('Get login and password')}
          onClose={() => setSandboxModal(false)}
          title={t('Try for free')}
        />
      )}
      {modal && (
        <ContactForm
          onClose={() => setModal(undefined)}
          title={modal.title}
          requestType={modal.requestType}
        />
      )}
      <section className={s.section}>
        <div className={s.shadow}>
          <GatsbyImage
            alt={t('Building')}
            title={t('Building')}
            image={images.bg.childImageSharp.gatsbyImageData}
            className={s.image_bg}
            imgClassName={s.image_position}
          />
        </div>
        <Container
          className={s.container}
          style={{
            paddingTop: 64,
            paddingBottom: region === AppRegionEnum.Ru ? 48 : 100,
          }}
        >
          <Typography variant="h2" size={40} color="white">
            {t('_about_title')}
          </Typography>
          <div
            className={cn(s.content, {
              [s.content_signal]: region === AppRegionEnum.Ru,
              [s.content_signax]: region === AppRegionEnum.Us,
            })}
          >
            <div className="w-100">
              <Typography variant="h3" color="white" className={s.textTitle}>
                {t('_about_lead')}
              </Typography>
            </div>
            <div className="w-100">
              <Typography variant="body1" size={18} color="white" mb={32}>
                {t('_about_text')}
              </Typography>
            </div>
            <div className={s.buttons}>
              <Button
                size="large"
                onClick={() =>
                  region === AppRegionEnum.Ru
                    ? setModal({
                        title: t('Try for free'),
                        requestType: 'Trial',
                      })
                    : setSandboxModal(true)
                }
                color="white"
              >
                {t('Try for free')}
              </Button>
              <Button
                size="large"
                onClick={() =>
                  setModal({
                    title: t('Request a demo of Signal'),
                    requestType: 'Demo',
                  })
                }
                className="mt-4"
                color="transparent"
              >
                {region === AppRegionEnum.Ru
                  ? t('Request a demo_1')
                  : t('Request demo')}
              </Button>
              {region === AppRegionEnum.Ru && (
                <Button
                  size="large"
                  onClick={() => toLink(telegramLink)}
                  className="mt-4"
                  color="transparent"
                >
                  <TelegramLogo style={{ marginRight: 10 }} />
                  {t('Our telegram channel')}
                </Button>
              )}
            </div>
          </div>
        </Container>
      </section>
    </>
  )
}

export default AboutSection

const imagesQuery = graphql`
  query {
    bg: file(relativePath: { eq: "home/signax-about-bg.png" }) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED)
      }
    }
  }
`
